import React from 'react'
import type {EventDateInformation} from '@wix/events-moment-commons'
import {DatesState} from '../types/state'

interface IDatesContext {
  getEventDateInformation: (id: string) => EventDateInformation
  getCommonDateInformation: (id: string) => string
}

const DatesContext = React.createContext<IDatesContext>(null)

interface DatesProviderProps {
  dates: DatesState
}

export const DatesProvider: React.FC<DatesProviderProps> = ({children, dates}) => {
  const getEventDateInformation = (id: string): EventDateInformation => dates.events[id]

  const getCommonDateInformation = (id: string): string => dates.common[id]

  return (
    <DatesContext.Provider value={{getEventDateInformation, getCommonDateInformation}}>
      {children}
    </DatesContext.Provider>
  )
}

export const useEventDateInformation = (id: string) => {
  return React.useContext<IDatesContext>(DatesContext).getEventDateInformation(id)
}

export const useCommonDateInformation = (id: string) => {
  return React.useContext<IDatesContext>(DatesContext).getCommonDateInformation(id)
}
