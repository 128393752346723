import type {WEEKDAY} from '@wix/wix-events-commons-statics'
import type {CalendarDayV2} from '@wix/events-moment-commons'
import {setBaseEnvironment, setFormFactor} from '../../../commons/actions/environment'
import {updateSiteSettings} from '../../../commons/actions/site-settings'
import {RIBBON} from '../../../commons/constants/ribbons'
import {CommonState, CommonStoreExtraArgs, DemoEvents} from '../../../commons/types/state'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateComponent, updateComponentDraft, updateStyleParams} from '../actions/component'
import {createEvent, shareEvent} from '../actions/event'
import {loadEventsPage} from '../actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {openMembersModal} from '../actions/members'
import {navigateToPage, onLinkNavigatedToPage} from '../actions/navigate-to-page'
import {updateSettings, updateStyle} from '../actions/sdk'
import {Api} from '../utils/api'
import {resized} from '../actions/resize'

export interface Actions {
  addCalendarMonth: typeof addCalendarMonth
  resetCalendar: typeof resetCalendar
  subCalendarMonth: typeof subCalendarMonth
  createEvent: typeof createEvent
  updateComponent: typeof updateComponent
  updateComponentDraft: typeof updateComponentDraft
  updateSiteSettings: typeof updateSiteSettings
  navigateToPage: typeof navigateToPage
  onLinkNavigatedToPage: typeof onLinkNavigatedToPage
  updateStyleParams: typeof updateStyleParams
  updateStyle: typeof updateStyle
  updateSettings: typeof updateSettings
  setBaseEnvironment: typeof setBaseEnvironment
  closeListLayoutItems: typeof closeListLayoutItems
  openListLayoutItem: typeof openListLayoutItem
  openMonthlyCalendarPopup: typeof openMonthlyCalendarPopup
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
  closeMonthlyCalendarEvent: typeof closeMonthlyCalendarEvent
  widgetLoaded: typeof widgetLoaded
  openMembersModal: typeof openMembersModal
  shareEvent: typeof shareEvent
  resized: typeof resized
  setFormFactor: typeof setFormFactor
  loadEventsPage: typeof loadEventsPage
}

export interface State extends CommonState {
  events: Events
  component: WidgetComponentConfig
  demoEvents: DemoEvents
  layout: Layout
  calendarLayout: CalendarLayout
  membersEnabled: boolean
  paidPlansEnabled: boolean
  needsResize: boolean
  owner: boolean
  memberLoggedIn: boolean
}

export interface Events {
  events: ExtendedEvent[]
  moreLoading: boolean
  hasMore: boolean
}

export interface Layout {
  listItemOpened: string
  forceShowRibbon: RIBBON
}

export enum CalendarLoading {
  DEFAULT = 1,
  PREVIOUS,
  NEXT,
}

export interface CalendarLayout {
  referenceDate: string
  today: string
  month: Dictionary<{
    next: string
    previous: string
    fullMonthText: string
  }>
  monthly: MonthlyCalendarLayout
  weekDayNames: WEEKDAY[]
  loadedPages: string[]
  loading: CalendarLoading
  events: Dictionary<string[]>
  demoEvents: Dictionary<string[]>
  days: Dictionary<CalendarDayV2>
}

export interface MonthlyCalendarLayout {
  selectedDate: string
  selectedEventId: string
}

export type GetState = () => State

export interface StoreExtraArgs extends CommonStoreExtraArgs {
  serverApi: Api
}
